import React, {useState} from 'react';
import "../css/FavButton.css";


import Preloader from "./Preloader";

const FavButton = ({isFav,isWaiting,onClick}) => {


    return (
        <div className={isFav ? "fav-button is-fav default-button" : "fav-button default-button"} onClick={onClick}>
           <div className="icon-like" />
            <span>LIKE</span>
        </div>
    );
};

export default FavButton;