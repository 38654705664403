import React, {useContext, useEffect, useState} from 'react';
import '../../css/Modal.css';
import ButtonWithLoading from "../ButtonWithLoading";
import AppDataContext from "../context/AppDataContext";
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";

function ModalEditClient(props) {
  const {appData,setAppData} = useContext(AppDataContext);
  const modalClass = "edit-person";
  const [fields, setFields] = useState({
    email:props.client.email,
    first_name:props.client.first_name,
    last_name:props.client.last_name,
    phone:props.client.phone,
    lead_status_id:props.client.lead_status_id,
    lead_source_id:props.client.lead_source_id,
  });
  const [errors, setErrors] = useState({email:"",first_name:"",last_name:"",phone:"",lead_status_id:"",lead_source_id:""});
  const [isLoading,setIsLoading] = useState(false);


  function hideModal(){
    appConst.closeModal(modalClass);
  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = "Please type client email";
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    }

    if(!fields["first_name"]){
      formIsValid = false;
      errors["first_name"] = "Please type client first name";
    }

    if(!fields["last_name"]){
      formIsValid = false;
      errors["last_name"] = "Please type client last name";
    }

    setErrors(errors)
    return formIsValid;
  }


  function onSave(){

    if(handleValidation())
    {
      setIsLoading(true);
      apiConst.clientUpdate(fields,props.client.id).then(result => {
        setIsLoading(false);

        if(result.response)
        {
          props.onConfirm(result.client);
          hideModal();
        }
      })

    }

  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">{props.modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="modal-body-title">{props.title}</h3>
            
            <div className="form-group">
              <div className="form-element">
                <label htmlFor="full-name">First Name</label>
                <input type="text" className={errors["first_name"] ? "form-control is-invalid" : "form-control"} id="first_name" placeholder="First name" defaultValue={props.client.first_name} onChange={(e) => handleChange("first_name",e)} />
                {
                  errors["first_name"] && <div className="invalid-feedback">
                    {errors["first_name"]}
                  </div>
                }
              </div>
              <div className="form-element">
                <label htmlFor="full-name">Full Name</label>
                <input type="text" className={errors["last_name"] ? "form-control is-invalid" : "form-control"} id="last_name" placeholder="Last name" defaultValue={props.client.last_name} onChange={(e) => handleChange("last_name",e)} />
                {
                  errors["last_name"] && <div className="invalid-feedback">
                    {errors["last_name"]}
                  </div>
                }
              </div>

              <div className="form-element">
                <label htmlFor="phone">Phone</label>
                <input type="phone" className={errors["phone"] ? "form-control is-invalid" : "form-control"} id="phone" placeholder="Phone" defaultValue={props.client.phone} onChange={(e) => handleChange("phone",e)} />
                {
                  errors["phone"] && <div className="invalid-feedback">
                    {errors["phone"]}
                  </div>
                }
              </div>


              <div className="form-element">
                <label htmlFor="email">Email</label>
                <input type="email" className={errors["email"] ? "form-control is-invalid" : "form-control"} id="email" placeholder="Email" defaultValue={props.client.email} onChange={(e) => handleChange("email",e)} />
                {
                  errors["email"] && <div className="invalid-feedback">
                    {errors["email"]}
                  </div>
                }
              </div>


              <div className="form-element">
                <label htmlFor="lead-status">Lead Status</label>
                <select className={errors["lead_status_id"] ? "form-control is-invalid" : "form-control"} id="lead-status"  defaultValue={props.client.lead_status_id} onChange={(e) => handleChange("lead_status_id",e)}>
                  { appData.leadStatuses && appData.leadStatuses.map( l => (
                      <option key={l.id} value={l.id}>{l.label}</option>
                  ))}
                </select>
                {
                  errors["lead_status_id"] && <div className="invalid-feedback">
                    {errors["lead_status_id"]}
                  </div>
                }
              </div>


              <div className="form-element">
                <label htmlFor="lead-source">Lead Source</label>
                <select className={errors["lead_source_id"] ? "form-control is-invalid" : "form-control"} id="lead-source" defaultValue={props.client.lead_source_id} onChange={(e) => handleChange("lead_source_id",e)}>
                  { appData.leadStatuses && appData.leadSources.map( l => (
                      <option key={l.id} value={l.id}>{l.label}</option>
                  ))}
                </select>
                {
                  errors["lead_source_id"] && <div className="invalid-feedback">
                    {errors["lead_source_id"]}
                  </div>
                }
              </div>


            </div>
            
            
            
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>{props.cancelText}</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label={props.confirmText} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalEditClient;