import React, {useContext, useEffect, useState} from 'react';
import "../css/FavoritesSubMenu.css";
import FavoriteList from "./FavoriteList";
const FavoritesSubMenu = (props) => {


    return (
        <div className="favorites-menu side-menu">

            <div className="inner">
                <div className={"title"}>
                    <h2>FAVORITES</h2>
                </div>
                <div className="content">
                    <FavoriteList />
                </div>

            </div>

        </div>
    );
};

export default FavoritesSubMenu;