import React, {useEffect} from 'react';
import Counter from 'react-number-counter';

function NumberBox(props) {

  const start = (props.start) ? parseInt(props.start) : 0;
  const delay = (props.delay) ? props.delay : 500 / (parseInt(props.number) - start);

  return (

    <div className={props.className}>
        <div className="number">
            {props.number >= 0 && <Counter start={start} end={props.number} delay={delay} />}
            {props.number < 0 && 0}
        </div>
        <div className="text">{props.text}</div>
    </div>

  );
  
}

export default NumberBox;