export const buildingConst = {
    "type_14": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"942.03 585.78 942.03 701.48 592.1 716.3 593.58 606.94 619.29 606.32 619.15 587.86 671.17 587.78 691.15 592.61 942.03 585.78"},
            {status : 1, level: 1,points:"592.1 716.3 593.58 606.94 233.35 615.75 235.01 752.75 456.95 741.77 450.26 734.85 611.24 728.95 592.1 716.3"},
            {status : 1, level: 2,points:"960.5 422.47 691.15 422.47 617.96 423.3 619.15 587.86 671.17 587.78 691.15 592.61 942.03 585.78 942.03 450.11 960.76 450.11 960.5 422.47"},
            {status : 1, level: 3,points:"215.87 428.47 230.02 412.48 617.9 416.81 619.29 606.32 593.58 606.94 233.35 615.75 215.87 572.47 215.87 428.47"},
            {status : 1, level: 4,points:"1609.26 708.32 1609.25 605.76 1431.46 610.76 1431.46 622.41 1368.46 624.93 1368.46 717.3 1609.26 708.32"},
            {status : 1, level: 5,points:"1368.46 717.3 1368.46 624.93 1168.1 633.23 927.86 593.17 927.86 662.36 1127.31 716.47 1127.48 729.78 1368.46 717.3"},
            {status : 1, level: 6,points:"1625.07 466.59 1429.46 466.59 1431.46 610.76 1609.25 605.76 1610.08 489.23 1625.07 489.4 1625.07 466.59"},
            {status : 1, level: 7,points:"1168.1 467.59 1429.46 466.59 1431.46 622.41 1168.1 633.23 927.86 593.17 928.37 467.59 1168.1 467.59"},
        ]
    },
    "type_4": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1651.22 368.13 1447.77 342.22 1229.88 417.61 1230.51 523.55 1261.65 523.62 1261.65 548.36 1225.73 548.36 1226.32 652.08 1425.38 666.15 1602.07 652.86 1599.13 546 1650.37 540.5 1650.37 514.78 1615.03 513.93 1615.62 402.88 1651.22 394.05 1651.22 368.13"},
            {status : 1, level: 1,points:"1261.65 377.56 1030.78 355.18 934.7 415.25 934.2 534.22 923.59 534.22 924.18 674.39 1226.32 669.09 1225.73 548.36 1261.65 548.36 1261.65 523.62 1230.51 523.55 1229.85 412.89 1261.65 403.47 1261.65 377.56"},
            {status : 1, level: 2,points:"627.94 455.89 667.99 319.84 963.64 347.52 963.64 377.56 934.78 395.81 934.2 534.22 923.59 534.22 924.18 674.39 698.02 674.39 698.02 531.86 663.86 530.69 663.13 499.32 657.24 499.18 657.09 474.29 628.56 473.56 627.94 455.89"},
            {status : 1, level: 3,points:"61.33 169.55 557.12 231.72 574.34 495.35 551.96 495.35 553.73 525.38 543.13 525.38 543.72 675.57 531.94 689.38 155.6 700.3 155.6 501.83 60.77 475.91 60.77 427.03 156.77 434.93 157.36 291.57 60.77 216.77 61.33 169.55"},

        ]
    },
    "type_10a": {
        viewBox: "0 0 1920 1080",
        floorPath : [
            {status : 1, level: 0,points:"580.44 297.93 1257.76 509.36 1257.97 544.38 1246.34 549.53 1246.5 636.9 1258.86 639.17 1258.86 662.61 1258.86 867.13 581.26 919.69 242.03 880.38 238.49 726.66 214.62 729 194.32 712.53 194.32 643.18 196.97 517.25 580.44 297.93"}
        ]
    },
    "type_11b": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"530.1,298.9 713.9,287.7 713.9,272.7 855.2,265.2 907.7,275.2 966.5,272.7 1149,315.2 1149,597.7 \n" +
                    "\t685.2,661.5 355.5,655.1 352.6,556.5 385.1,556.5 388.8,416.4 530.1,411.4"},
        ]
    },
    "type_1a": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"424.42 478.79 436.86 505.36 434.04 760.11 1146.87 981.36 1464.26 904.61 1464.26 865.93 1614.37 848.13 1656.91 773.99 1656.91 623.74 1656.91 574.2 1544.62 566.28 1543.77 483.37 1594.09 479.64 1596.77 461.19 1533.16 448.82 1533.1 391.43 1502.14 381.68 1462.49 385.35 1149.69 300.6 657.47 415.16 657.55 421.64 424.42 478.79"},
        ]
    },
    "type_20": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"763.8 0.5 570.42 92.94 608.76 112.95 617.1 176.3 448.73 274.65 467.06 436.36 622.1 509.71 697.12 466.37 972.19 588.06 1048.87 528.05 1193.91 588.06 1342.28 436.36 1360.61 227.97 1397.29 187.97 1188.9 122.95 1157.23 89.61 1123.89 81.27 1083.88 87.94 1007.19 69.6 1007.19 44.6 1045.54 22.93 982.19 0.5 763.8 0.5"},
        ]
    },

}