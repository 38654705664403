import React, {useContext, useEffect, useState} from 'react';
import SubMenuItem from "./SubMenuItem";
import '../css/SubMenu.css';
import '../css/SearchSubMenu.css';
import {appConst} from "../constant/appConst";
import SubMenuTitle from "./SubMenuTitle";
import LocationContext from "./context/LocationContext";
import AppDataContext from "./context/AppDataContext";
import ListItem from "./ListItem";
import {appUtil} from "../constant/appUtil";
import SearchMenuItem from "./SearchMenuItem";
const SearchSubMenu = ({locations,onClickButtonBack}) => {
    const {appData, setAppData} = useContext(AppDataContext);
    const [listItems,setListItems] = useState(null);
    const {locationCategory,setLocationCategory} = useContext(LocationContext);

    useEffect(()=> {
        if(appData.loaded)
        {
            let rows = [];
            let sorted = appData.properties.sort(appUtil.dynamicSort("code"));
            sorted.forEach(function(property){
                rows.push(<SearchMenuItem title={property.code} status={property.status_id} link={"/property-view/" + property.id} key={property.id} />);
            })
            setListItems(rows)
        }
    },[appData.loaded])

    function changeActiveCategory(categoryId) {
        setLocationCategory(categoryId)
    }
    function onSearchInputChange(e){
        let searchText = e.target.value
        console.log(e.target.value,e.target.value.length)

        let rows = [];
        appData.properties.forEach(function(property){
            if(property.code.includes(searchText.toUpperCase()))
            rows.push(<SearchMenuItem title={property.code} status={property.status_id}  link={"/property-view/" + property.id} key={property.id} />);
        })
        setListItems(rows)



    }
    return (
        <div className="search-menu side-menu">
            <div className="inner">
                <div className={"title"}>
                    <h2>SEARCH</h2>
                </div>
                <div className="search-container">
                    <input type="text" className={"search-input"} placeholder={"Search by property code"} onChange={onSearchInputChange}/>
                </div>

                <div className="search-list">

                    {listItems}


                </div>
            </div>

        </div>
    );
};

export default SearchSubMenu;