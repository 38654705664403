import React, {useEffect,useState} from 'react';
import {Link} from "react-router-dom";
import PropertyPlan from "./PropertyPlan";
import {appUtil} from "../../constant/appUtil";

function FloorPlan({appData,properties,floorId,planSvg}) {

    const [plan,setPlan] = useState([]);

    useEffect(()=>{

        if(properties.length)
        {

            let updatedPlan = []
            properties.forEach(function(property,i){

                if(property.flatLevel === parseInt(floorId))
                {

                    let className = "item " + appUtil.convertToSlug(property.status.label);
                    if(appData.isFilterModeActive)
                    {

                        className = "item disabled " + appUtil.convertToSlug(property.status.label);
                        appData.filteredProperties.forEach((filteredProp) => {
                            if(filteredProp.id === property.id)
                            {
                                className = "item filtered " + appUtil.convertToSlug(property.status.label);
                            }

                        })
                    }
                    let link = "/property-view/" + property.id;
                    let title = property.number;
                    let roomType = property.room_type.label;
                    let svgValue = parseInt(property.is_dublex) ? planSvg.propertyPlan.find(plan => (plan.number === property.number && plan.isDublex)) :planSvg.propertyPlan.find(plan => plan.number === property.number);

                    updatedPlan.push (<PropertyPlan key={i} link={link} className={className} svgValue={svgValue} title={title} roomType={roomType}/>)
                }
            });

            setPlan(updatedPlan);
        }


    },[properties,appData.isFilterModeActive,appData.filteredProperties]);

    function isInFiltered(property){

        return false;
    }

    return (

        <svg xmlns="http://www.w3.org/2000/svg" viewBox={planSvg.viewBox}>

            {plan}
            <path id="Layer3_0_1_STROKES" data-name="Layer3 0 1 STROKES" className="cls-1" d={planSvg.floorPlan} />




        </svg>

    );

}

export default FloorPlan;