import React, {useEffect, useState} from 'react';
import '../../css/Modal.css';
import ButtonWithLoading from "../ButtonWithLoading";
import {appConst} from "../../constant/appConst";
import {appUtil} from "../../constant/appUtil";
import {apiConst} from "../../constant/apiConst";

function ModalAssignClient(props) {
  
  const modalClass = "assign-person";

  const [fields, setFields] = useState({
    client_id : 0
  });
  const [errors, setErrors] = useState({client_id:""});
  const [isLoading,setIsLoading] = useState(false);
  const [clients,setClients] = useState(false);

  useEffect(() => {
    let assignableClients = []
    apiConst.getClients().then(clients => {
      clients.forEach(client => {
          if(!isInCurrentClients(client))
            assignableClients.push(client);
      })
      setClients(assignableClients);
    })
  },[props.person])



  function isInCurrentClients(client){
    let currentClientIds = [];
    props.person.clients.forEach(currentClient => {
      currentClientIds.push(currentClient.id);
    })
    return currentClientIds.includes(client.id);
  }
  function hideModal(){
    appConst.closeModal(modalClass);
  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    if(!fields["client_id"])
    {
      formIsValid = false;
      errors["client_id"] = "Please select a client for assign";
    }



    setErrors(errors)
    return formIsValid;
  }
  function onSave(){

    if(handleValidation())
    {
      setIsLoading(true);

      apiConst.assingUserForClient(props.person.id,fields["client_id"]).then(result => {
        setIsLoading(false);

        if(result.response)
        {
          props.onConfirm(result.client);
          hideModal();
        }
      })

    }

  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="modal-body-title">Assign Client</h3>
            
            <div className="form-group">
              <div className="form-element">
                <label htmlFor="lead-source">Clients</label>
                <select className={errors["client_id"] ? "form-control is-invalid" : "form-control"} id="lead-source" onChange={(e) => handleChange("client_id",e)}>
                  <option value="">Select a client</option>
                  { clients && clients.map( c => (
                      <option key={c.id} value={c.id}>{c.first_name + " " + c.last_name}</option>
                  ))}
                </select>
                {
                  errors["client_id"] && <div className="invalid-feedback">
                    {errors["client_id"]}
                  </div>
                }
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>Cancel</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label="Save" isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalAssignClient;