import React, {useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import '../css/BuildingView.css';
import BuildingSvg from './svg/BuildingSvg.js';
import AppDataContext from "./context/AppDataContext";
import BuildingLabel from "./BuildingLabel";
import {buildingConst} from "../constant/buildingConst";
import {appConst} from "../constant/appConst";
import {Image} from "react-img-placeholder";
import FavContext from "./context/FavContext";
import ClientContext from "./context/ClientContext";

function BuildingView(props) {

	const {appData, setAppData} = useContext(AppDataContext);
	const {favs, setFavs} = useContext(FavContext);
	const {client,setClient} = useContext(ClientContext);

	const [buildingType,setBuildingType] = useState("");
	const [isVilla,setIsVilla] = useState(false);
	const [buildingCode,setBuildingCode] = useState("");
	const [labels,setLabels] = useState([]);
	let buildingId = parseInt(props.location.pathname.split("/").pop());


	useEffect(() => {
		if(appData.loaded)
		{

			let updatedLabel = [];
			let favProps = [];

			favs.forEach((fav) => {
				favProps.push(fav.property.number)
			})
			appData.buildings.map(building => {
				if(building.id === buildingId){

					setBuildingCode(building.code)
					setBuildingType(building.class_name)
					setIsVilla(building.is_villa);

					if(!building.is_villa){
							let flat = building.code === "14" ? ["A-1","A-2","A-3","A-4","B-1","B-2","B-3","B-4"]: ["A","B","C","D"];

							for (let i = 0; i <= flat.length; i++) {
								let propTitle = building.code + flat[i];
								updatedLabel.push(
									<BuildingLabel key={i} propId={i} title={propTitle} isFav={favProps.includes(propTitle)}  total={getTotalFilteredPropertyByFlatLevel(i)} />
								);
							}


					}
					else {
						let properties = getPropertyByBuilding()

						if(properties.length){
							buildingConst[building.class_name].floorPath.forEach(function(path){
								properties.forEach(function(property,i){
									if(path.number === property.number)
										updatedLabel.push(
											<BuildingLabel key={i} propId={property.id} isFav={true} title={property.code} total={isPropertyInFiltered(property.id)} />
										);

								})
							});
						}

					}

					setLabels(updatedLabel);
				};
			});
		}

	},[appData,props.loaded360,favs,client]);



	// For villa property
	function getPropertyByBuilding(){
		let properties = [];
		appData.properties.forEach(function(property,i){
			if(buildingId === property.building.id)
			{
				properties.push(property)
			}
		})
		return properties;
	}
	function isPropertyInFiltered(id) {
		let isInFiltered = 0
		appData.filteredProperties.forEach(function(property,i){
			if(id === property.id)
			{
				isInFiltered = 1;
			}
		})
		return isInFiltered
	}
	function getTotalFilteredPropertyByFlatLevel(level)
	{
		var total = 0;
		var sold = 0;
		appData.filteredProperties.forEach(function(property,i){
			if(level === property.flatLevel && buildingId === property.building.id)
			{
				if(property.status_id === appConst.status.sold) sold++;
				total++;
			}
		})
		if(sold === total) return 0;
		return total;
	}

	if(!appData) return null;



	return (

		<section className={"primary building " + buildingType.toLowerCase()}>
			<div className="labels">
				{(buildingType) && labels}
			</div>

			<div className="main-title">
				<div className="number">{(buildingCode === "V1" || buildingCode === "V1") ? "V" : buildingCode}</div>
			</div>

			<Link to="/">
				<button className="close-button">
					<div></div>
					<div></div>
				</button>
			</Link>

			<div className="inner">

				{ buildingType && <Image className="main-image" src={require('../img/buildings/' + buildingType.toLowerCase() + '.webp')} width="1920" height={"1080"} placeholderSrc={require('../img/placeholder.webp')}/> }

				<div className="paths">
					{buildingType && <BuildingSvg buildingId={buildingId} buildingType={buildingType} isVilla={isVilla} buildingProperties={getPropertyByBuilding()} floorStatuses={appData.floorStatuses[buildingCode]} />}

				</div>



			</div>

		

		</section>

	);
}

export default withRouter(BuildingView);