import React, {useContext, useEffect, useState} from 'react';
import '../../css/Modal.css';
import ButtonWithLoading from "../ButtonWithLoading";
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import {appUtil} from "../../constant/appUtil";
import UserContext from "../context/UserContext";

function ModalEditSalesPerson(props) {
  
  const modalClass = "edit-person";
  const {user,setUser} = useContext(UserContext);
  const [fields, setFields] = useState({
    email:props.person.email || "",
    name:props.person.name || "",
    phone:props.person.phone || "",
    password:props.person.password || ""
  });
  const [errors, setErrors] = useState({email:"",name:"",phone:"",password:""});
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
      setFields({
        email:props.person.email || "",
        name:props.person.name || "",
        phone:props.person.phone || "",
        password:props.person.password || ""
      })
  },[props])

  function hideModal(){

      appConst.closeModal(modalClass);
    if(props.onHide) props.onHide();
  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = "Please type user email";
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    }

    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Please type user name";
    }

    if(appUtil.isEmpty(props.person))
    {
      if(!fields["password"]){
        formIsValid = false;
        errors["password"] = "Please type user password";
      }
    }

    setErrors(errors)
    return formIsValid;
  }
  function onSave(){

    if(handleValidation())
    {
      setIsLoading(true);

      if(appUtil.isEmpty(props.person))
      {
        apiConst.userCreate(fields,user.id,props.role).then(result => {
          setIsLoading(false);
          if(result.response)
          {
            props.onConfirm("create",result.user);
            hideModal();
          }
        })

      }
      else
      {
        apiConst.userUpdate(fields,props.person.id).then(result => {
          setIsLoading(false);

          if(result.response)
          {
            props.onConfirm("update",result.user);
            hideModal();
          }
        })
      }


    }

    console.log(appUtil.isEmpty(props.person))

  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">{props.modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="modal-body-title">{props.title}</h3>
            
            <div className="form-group">

              <div className="form-element">
                <label htmlFor="full-name">Full Name</label>
                <input type="text" className={errors["name"] ? "form-control is-invalid" : "form-control"} id="name" placeholder="Full name" defaultValue={props.person.name} onChange={(e) => handleChange("name",e)} />
                {
                  errors["name"] && <div className="invalid-feedback">
                    {errors["name"]}
                  </div>
                }
              </div>

              <div className="form-element">
                <label htmlFor="email">Email</label>
                <input type="email" className={errors["email"] ? "form-control is-invalid" : "form-control"} id="email" placeholder="Email" defaultValue={props.person.email} onChange={(e) => handleChange("email",e)} />
                {
                  errors["email"] && <div className="invalid-feedback">
                    {errors["email"]}
                  </div>
                }
              </div>

              <div className="form-element">
                <label htmlFor="phone">Phone</label>
                <input type="phone" className={errors["phone"] ? "form-control is-invalid" : "form-control"} id="phone" placeholder="Phone" defaultValue={props.person.phone} onChange={(e) => handleChange("phone",e)} />
                {
                  errors["phone"] && <div className="invalid-feedback">
                    {errors["phone"]}
                  </div>
                }
              </div>


              <div className="form-element">
                <label htmlFor="password">Password</label>
                <input type="password" className={errors["password"] ? "form-control is-invalid" : "form-control"} id="password" placeholder="Password"   onChange={(e) => handleChange("password",e)} />
                {
                  errors["password"] && <div className="invalid-feedback">
                    {errors["password"]}
                  </div>
                }
              </div>

            </div>
            
            
            
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>{props.cancelText}</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label={props.confirmText} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalEditSalesPerson;