import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link, useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";



function UserLogin() {

  const [fields, setFields] = useState({email:"",password:""});
  const [errors, setErrors] = useState({email:"",password:""});
  const [isLoading,setIsLoading] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();



  useEffect(() => {
    if(user)
    {

      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
      setAlert("Hello " + user.name);
      history.push("/");
    }

  },[user]);



  function onClickLogin(){
    if(handleValidation())
    {
      setIsLoading(true);
      apiConst.userLogin(fields).then(result => {
        setIsLoading(false);

        if(result.response)
        {
          setUser(result.user);
        }
        else
        {
          errors["email"] = "Email address or password is wrong";
          errors["password"] = "";
          setErrors(errors);
          setIsLoading(false);
        }

      })
    }

  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = "Please type your email";
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    }

    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = "Please type password";
    }

    setErrors(errors)
    return formIsValid;
  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }


  return (
    <section  className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <div className="logo mb-5"><img src={require("../img/logo-splash.svg")}  /></div>
          <h2>SALES LOGIN</h2>
          <div className="form-element">
            <input type="text" className={errors["email"] ? "form-control is-invalid" : "form-control"}  id="email" placeholder="E-mail" onChange={(e) => handleChange("email",e)} />
            {
              errors["email"] && <div className="invalid-feedback">
                {errors["email"]}
              </div>
            }
          </div>

          <div className="form-element">
          <input type="password" className={errors["password"] ? "form-control is-invalid" : "form-control"} id="password" placeholder="Password" onChange={(e) => handleChange("password",e)} />
          {
            errors["password"] && <div className="invalid-feedback">
              {errors["password"]}
            </div>
          }
          </div>
          <div><Link to={"/userForgotPassword"}>Forgot Password</Link></div>
          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onClickLogin} label="ADMIN LOGIN" isLoading={isLoading} />
          </div>



        </div>

    </div>

      <Link to="/" className="sales-login-link">Go Home</Link>
    </section>
  );
}

export default UserLogin;