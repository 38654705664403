export const propertyConst = {
    slider : {
        "1a" : [
            {source : require("../img/property_slider/1a/i1.jpg")},
            {source : require("../img/property_slider/1a/i2.jpg")},
            {source : require("../img/property_slider/1a/i3.jpg")},
            {source : require("../img/property_slider/1a/i4.jpg")},
            {source : require("../img/property_slider/1a/i5.jpg")},
            {source : require("../img/property_slider/1a/i6.jpg")},
            {source : require("../img/property_slider/1a/i7.jpg")},
            {source : require("../img/property_slider/1a/i8.jpg")},
            {source : require("../img/property_slider/1a/i9.jpg")},
            {source : require("../img/property_slider/1a/i10.jpg")},
        ],
        "4" : [
            {source : require("../img/property_slider/4/i1.jpg")},
            {source : require("../img/property_slider/4/i2.jpg")},
            {source : require("../img/property_slider/4/i3.jpg")},
            {source : require("../img/property_slider/4/i4.jpg")},
            {source : require("../img/property_slider/4/i5.jpg")},
            {source : require("../img/property_slider/4/i6.jpg")},
            {source : require("../img/property_slider/4/i7.jpg")},
            {source : require("../img/property_slider/4/i8.jpg")},
            {source : require("../img/property_slider/4/i9.jpg")},
            {source : require("../img/property_slider/4/i10.jpg")},

        ],
        "10a" : [
            {source : require("../img/property_slider/10a/i1.jpg")},
            {source : require("../img/property_slider/10a/i2.jpg")},
            {source : require("../img/property_slider/10a/i3.jpg")},
            {source : require("../img/property_slider/10a/i4.jpg")},
            {source : require("../img/property_slider/10a/i5.jpg")},
            {source : require("../img/property_slider/10a/i6.jpg")},
            {source : require("../img/property_slider/10a/i7.jpg")},
            {source : require("../img/property_slider/10a/i8.jpg")},
            {source : require("../img/property_slider/10a/i9.jpg")},


        ],
        "11b" : [
            {source : require("../img/property_slider/11b/i1.jpg")},
            {source : require("../img/property_slider/11b/i2.jpg")},
            {source : require("../img/property_slider/11b/i3.jpg")},
            {source : require("../img/property_slider/11b/i4.jpg")},
            {source : require("../img/property_slider/11b/i5.jpg")},
            {source : require("../img/property_slider/11b/i6.jpg")},
            {source : require("../img/property_slider/11b/i7.jpg")},
        ],
        "14a1" : [
            {source : require("../img/property_slider/14a1/i1.jpg")},
            {source : require("../img/property_slider/14a1/i2.jpg")},
            {source : require("../img/property_slider/14a1/i3.jpg")},
            {source : require("../img/property_slider/14a1/i4.jpg")},

        ],
        "14a2" : [
            {source : require("../img/property_slider/14a2/i1.jpg")},
            {source : require("../img/property_slider/14a2/i2.jpg")},
            {source : require("../img/property_slider/14a2/i3.jpg")},
            {source : require("../img/property_slider/14a2/i4.jpg")},

        ],
        "20" : [
            {source : require("../img/property_slider/20/i1.jpg")},
            {source : require("../img/property_slider/20/i2.jpg")},
            {source : require("../img/property_slider/20/i3.jpg")},
            {source : require("../img/property_slider/20/i4.jpg")},
            {source : require("../img/property_slider/20/i5.jpg")},
            {source : require("../img/property_slider/20/i6.jpg")},
            {source : require("../img/property_slider/20/i7.jpg")},
        ],

    },
    thumb : {
        "1a" : [
            {source : require("../img/property_thumbs/1a/i1.jpg")},
        ],
        "4" : [
            {source : require("../img/property_thumbs/4/i1.jpg")},
        ],
        "10a" : [
            {source : require("../img/property_thumbs/10a/i1.jpg")},
        ],
        "11b" : [
            {source : require("../img/property_thumbs/11b/i1.jpg")},
        ],
        "14" : [
            {source : require("../img/property_thumbs/14/i1.jpg")},
        ],
        "20" : [
            {source : require("../img/property_thumbs/20/i1.jpg")},
        ],


    },
    floorplan_slider : {
        "1a" : [
            {source : require("../img/property_floorplan/1a/i1.jpg")},
        ],
        "4" : [
            {source : require("../img/property_floorplan/4/i1.jpg")},
            {source : require("../img/property_floorplan/4/i2.jpg")},
        ],
        "10a" : [
            {source : require("../img/property_floorplan/10a/i1.jpg")},
            {source : require("../img/property_floorplan/10a/i2.jpg")},
            {source : require("../img/property_floorplan/10a/i3.jpg")},
        ],
        "11b" : [
            {source : require("../img/property_floorplan/11b/i1.jpg")},
            {source : require("../img/property_floorplan/11b/i2.jpg")},

        ],
        "14a1" : [
            {source : require("../img/property_floorplan/14/a1.jpg")},
        ],
        "14a2" : [
            {source : require("../img/property_floorplan/14/a2.jpg")},
        ],
        "14a3" : [
            {source : require("../img/property_floorplan/14/a3.jpg")},
        ],
        "14a4" : [
            {source : require("../img/property_floorplan/14/a4.jpg")},
        ],
        "14b1" : [
            {source : require("../img/property_floorplan/14/b1.jpg")},
        ],
        "14b2" : [
            {source : require("../img/property_floorplan/14/b2.jpg")},
        ],
        "14b3" : [
            {source : require("../img/property_floorplan/14/b3.jpg")},
        ],
        "14b4" : [
            {source : require("../img/property_floorplan/14/b4.jpg")},
        ],
        "20" : [
            {source : require("../img/property_floorplan/20/i1.jpg")},
            {source : require("../img/property_floorplan/20/i2.jpg")},
        ],
    },

    vr360 : {
        "10a" : "https://kuula.co/share/collection/79RZp?logo=1&info=1&fs=1&vr=0&thumbs=1",
        "14a1" : "https://wizio.co.uk/virtualtour/kvillas/a1/",
        "14a2" : "https://wizio.co.uk/virtualtour/kvillas/a2/",
        "14a3" : "https://wizio.co.uk/virtualtour/kvillas/a3/",
        "14a4" : "https://wizio.co.uk/virtualtour/kvillas/a4/",
        "14b1" : "https://wizio.co.uk/virtualtour/kvillas/a1/",
        "14b2" : "https://wizio.co.uk/virtualtour/kvillas/a2/",
        "14b3" : "https://wizio.co.uk/virtualtour/kvillas/a3/",
        "14b4" : "https://wizio.co.uk/virtualtour/kvillas/a4/",
        "4" : "https://kuula.co/share/collection/7FG0y?logo=1&info=1&fs=1&vr=0&thumbs=1",
        "11b" : "https://wizio.co.uk/projects/cy/kensington/karpasia/360/modern/",

    }
}