import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {appConst} from "../constant/appConst";
import ReactPlayer from "react-player";
import Slider from "./Slider";
import "../css/Gallery.css";

const Gallery = () => {
    const { id } = useParams();

    return (
        <section className="primary gallery">
            {(id === "exterior") && <Slider gallery={appConst.gallery.exterior}  />}
            {(id === "interior") && <Slider gallery={appConst.gallery.interior}  />}
            {(id === "social-areas") && <Slider gallery={appConst.gallery.social}  />}
            {(id === "social-area-tour") && <iframe src="https://cy.kd.mediterra2.wizio.co.uk/360/social" allowFullScreen="true" /> }

        </section>
    );

};

export default Gallery;