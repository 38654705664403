import React, {useContext, useEffect, useState} from 'react';
import '../css/Modal.css';
import ConfirmContext from "./context/ConfirmContext";

function Confirm() {
  const {confirm,setConfirm} = useContext(ConfirmContext);

  useEffect(() => {
    if(confirm)
    {
      if(confirm.show) showModal();
    }
  },[confirm]);



  function showModal(){

    document.querySelector("body").classList.add("modal-open");
    document.querySelector(".confirm-modal").classList.add("show");
    document.querySelector(".modal-backdrop").classList.add("show");

  }

  function hideModal(){
    //let newConfirm = {...confirm};
    //newConfirm.show = false;
    //newConfirm.text = "";
    //setConfirm(newConfirm);
    document.querySelector("body").classList.remove("modal-open");
    document.querySelector(".confirm-modal").classList.remove("show");
    document.querySelector(".modal-backdrop").classList.remove("show");

  }

  function confirmModal(){
    if(confirm.onConfirm) confirm.onConfirm();
    hideModal();
  }

  function cancelModal(){
    if(confirm.onUnconfirm) confirm.onUnconfirm();
    hideModal();
  }


  return (

    <div className="modal fade confirm-modal" id="default-modal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3>{confirm.question ? confirm.question : "Are you sure ?"}</h3>
            {confirm.text}
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={cancelModal}>No</button>
            <button type="button" className="default-button" onClick={confirmModal}>Yes</button>
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default Confirm;