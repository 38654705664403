import React, {useContext, useEffect, useState} from 'react';
import {Link, withRouter,useHistory} from "react-router-dom";
import UserContext from "../context/UserContext";
import ClientContext from "../context/ClientContext";
import {appConst} from "../../constant/appConst";
import ConfirmContext from "../context/ConfirmContext";
import FavContext from "../context/FavContext";
import {apiConst} from "../../constant/apiConst";
import ModalEditSalesPerson from "./ModalEditSalesPerson";
import ModalEditUser from "./ModalEditUser";


function DashboardHeader(props) {
  const history = useHistory();
  const {user,setUser} = useContext(UserContext);
  const {client,setClient} = useContext(ClientContext);
  const [menuItems,setMenuItems] = useState([]);
  const {confirm,setConfirm} = useContext(ConfirmContext);
  const {favs,setFavs} = useContext(FavContext);

  useEffect(() => {
    let items = [
      {slug: "home", link: "/dashboard/", title: "Dashboard"},
      {slug: "clients", link: "/dashboard/clients", title: "Clients"},
    ];

    if(user.role.id === appConst.role.general_manager)
    {
      items.splice(1,0,{slug: "general-managers", link: "/dashboard/general-managers", title: "General Managers"})
      items.splice(2,0,{slug: "sales-managers", link: "/dashboard/sales-managers", title: "Sales Managers"})
      items.splice(3,0,{slug: "sales-reps", link: "/dashboard/sales-reps", title: "Sales Reps"})
      items.splice(4,0,{slug: "brokers", link: "/dashboard/brokers", title: "Brokers"})
      items.splice(5,0,{slug: "property-actions", link: "/dashboard/property-actions", title: "Property Actions"})
      items.splice(6,0,{slug: "properties", link: "/dashboard/properties", title: "Properties"})
    }
    else if(user.role.id === appConst.role.sales_manager)
    {
      items.splice(1,0,{slug: "sales-reps", link: "/dashboard/sales-reps", title: "Sales Reps"})
      items.splice(2,0,{slug: "brokers", link: "/dashboard/brokers", title: "Brokers"})
      items.splice(3,0,{slug: "property-actions", link: "/dashboard/property-actions", title: "Property Actions"})
      items.splice(4,0,{slug: "properties", link: "/dashboard/properties", title: "Properties"})
    }
    
    setMenuItems(items)

  },[user])

  function onClickLogout(){
    setConfirm({show:true,text:"You want to terminate session",onConfirm : onConfirm})
  }
  function onConfirm(){
    if(client) apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
    setUser(null);
    setClient(null);
    setFavs([]);
    sessionStorage.clear();
    if(props.location.pathname.includes("dashboard"))
      history.push("/");
  }

  function saveUser(process,savedUser){

    setUser(savedUser);

  }

  function onCLickBack(){
    history.goBack()
  }



  return (

    <div className="container dashboard-header">
      <div className="row">
        
        <div className="col-3 user">
          <span className="name">{user.name}</span>
          <span className="edit-button" onClick={() => appConst.showModal("edit-user")}></span>
          <span className="logout-button" onClick={onClickLogout}/>
        </div>

        {!props.hideMenu && (
           <div className="col-9 dashboard-menu">
             <button onClick={onCLickBack} className={"back-button"}>&nbsp;</button>
             { menuItems && menuItems.map((item, index) =>
               <Link key={index} to={item.link} className={item.slug === props.slug ? "active" : ""}>{item.title}</Link>
             )}
         </div>
        )}


        <ModalEditUser title="Edit User" onConfirm={saveUser} confirmText="Save" cancelText="Cancel" />



        
        
        
      </div>

    </div>

  );
  
}

export default withRouter(DashboardHeader);