import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/GeneralManagers.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import UserContext from "../context/UserContext";

import ModalEditSalesPerson from "./ModalEditSalesPerson";
import ModalEditClient from "./ModalEditClient";
import ConfirmContext from "../context/ConfirmContext";
import {appUtil} from "../../constant/appUtil";



function GeneralManagers() {
    const {user,setUser} = useContext(UserContext);
    const [data1,setData1] = useState(null);
    const [generalManagers,setGeneralManagers] = useState(null);

    const [activePerson,setActivePerson] = useState(null);
    const {confirm,setConfirm} = useContext(ConfirmContext);

    useEffect(() => {
        apiConst.getGeneralManagers().then(generalManagers => {
                setGeneralManagers(generalManagers);
        })
    },[])

    useEffect(() => {
        if(generalManagers)
        {
            let data_1 = {
                className: "general-managers",
                title: "General Managers",
                onClickDelete: onClickDelete,
                headings: [],
                rows: []
            };
            generalManagers.forEach(generalManager => {
                data_1.rows.push({
                    generalManager: {id: generalManager.id, name: generalManager.name,email: generalManager.email,phone:generalManager.phone}
                })
            })
            setData1(data_1);


        }
    },[generalManagers])

    useEffect(() => {
        if(activePerson) appConst.showModal("edit-person");
    },[activePerson])

    function addPerson(){
        setActivePerson({})
    }

    function editPerson(person){
        setActivePerson(person)
    }

    function clearActivePerson(){
        setActivePerson(null)
    }

    function onClickDelete(id,name,tableData){

        setConfirm({show:true,text:"You want to delete "+name,onConfirm : () => onDeleteConfirm(id,tableData)})

    }

    function onDeleteConfirm(id,tableData){

        apiConst.userDelete(id).then(result => {
            if(result.response)
            {

                let data = {...tableData};
                data.rows = []
                tableData.rows.forEach((item) => {
                    if(item.generalManager.id !== id) data.rows.push(item);
                });
                setData1(data);

            }
        })
    }

    function savePerson(process,savedPerson){

        let data = {
            className: "general-managers",
            title: "General Managers",
            onClickDelete: onClickDelete,
            headings: [],
            rows: []
        };

        if(process === "create")
        {
            data.rows = [{generalManager : {id: savedPerson.id, name: savedPerson.name,email: savedPerson.email,phone:savedPerson.phone}},...data1.rows];
            setData1(data);

        }

    }
  

  return (

    <section className="primary dashboard sales-reps">
      <DashboardHeader slug="general-managers" />
  
        <div className="container">
        <div className="row">
          
        <div className="col-12">
          <DashboardTable data={data1} headerButton={{title: "Add"}} onHeaderButton={addPerson} onItemClick={editPerson} height="h-80" />
         </div>


          
        </div>

        

      </div>
        {activePerson && <ModalEditSalesPerson title={appUtil.isEmpty(activePerson) ? "Add General Manager" : "Edit General Manager"} person={activePerson} onConfirm={savePerson} onHide={clearActivePerson} confirmText="Save" cancelText="Cancel" role={appConst.role.general_manager} />}


    </section>

  );
  
}

export default GeneralManagers;