import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import '../../css/dashboard/DashboardTable.css';
import LoadingOverlay from "../LoadingOverlay";
import {appUtil} from "../../constant/appUtil";
import {appConst} from "../../constant/appConst";
import {apiConst} from "../../constant/apiConst";
import AlertContext from "../context/AlertContext";
import {appSettings} from "../../constant/appConst";

function DashboardTable(props) {
    const {alert,setAlert} = useContext(AlertContext);
    let rows;
    let header = "";
    let headings;

  if(props.data)
  {
       headings = props.data.headings.map((item, index)=>

          <th scope="col" key={index}>{item}</th>

      );



      if (props.data.title != "") {
          if (props.headerButton) {
              header = <div className="header"><h3>{props.data.title}</h3> <div className={"header-button"} onClick={()=>{ props.onHeaderButton(); }}>{props.headerButton.title}</div></div>;
          }
          else {
              header = <h3>{props.data.title}</h3>;
          }
      }


      switch (props.data.className) {

          case 'property-actions':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                      <td><Link to={"/dashboard/sales-rep/" + item.salesRep.id}>{item.salesRep.name}</Link></td>
                      <td>{item.status}</td>
                  </tr>

              );

              break;

          case 'sales-rep-property-actions':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                      <td>{item.status}</td>
                  </tr>

              );

              break;

          case 'properties':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/property/" + item.id}>{item.name}</Link></td>
                      <td>{item.floorType}</td>
                      <td>{item.netArea} {appSettings.areaUnit}</td>
                      <td onClick={()=>{ props.data.onClickPropertyColumn(item); }}>{item.status.label}</td>
                  </tr>

              );

              break;

          case 'recent-presentations':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                  </tr>

              );

              break;

          case 'recent-presentations-with-sales-rep':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/sales-rep/" + item.salesRep.id}>{item.salesRep.name}</Link></td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                  </tr>

              );

              break;

          case 'most-popular-properties':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td>{item.favorite}</td>
                  </tr>

              );

              break;

          case 'client-lead-status':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                      <td>{item.leadStatus}</td>
                  </tr>

              );

              break;

          case 'actions-history':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td>{item.status}</td>
                  </tr>

              );

              break;

          case 'favourite-properties':

              function onOptionClick(fav){
                  apiConst.favPropertyAction(fav.client_id,fav.property.id,appConst.status.reserved).then(result => {
                        if(result.response)  {
                            setAlert("Successfully reserved");
                            props.refreshFunction();
                        }
                  });
              }

              function onBuyClick(fav){
                  apiConst.favPropertyAction(fav.client_id,fav.property.id,appConst.status.sold).then(result => {
                      if(result.response)  {
                          setAlert("Successfully bought");
                          props.refreshFunction();
                      }
                  });
              }

              function onCancelClick(fav){
                  apiConst.favPropertyAction(fav.client_id,fav.property.id,appConst.status.available).then(result => {
                      if(result.response)  {
                          setAlert("Successfully canceled");
                          props.refreshFunction();
                      }
                  });
              }

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td align={"right"}>{
                          (() => {
                              if (item.property.status_id === appConst.status.available)
                                  return <div><button onClick={() => onOptionClick(item)} >RESERVE</button>&nbsp;&nbsp;&nbsp;<button onClick={() => onBuyClick(item)}>BUY</button></div>
                              else if ((item.property.status_id === appConst.status.reserved || item.property.status_id === appConst.status.sold) && item.client_id === item.current_client_id)
                                  return <button onClick={() => onCancelClick(item)} >CANCEL</button>
                              else if (item.property.status_id === appConst.status.not_released)
                                  return <span>Not Released</span>
                              else
                                 return <span>Sold or Reserved</span>
                          })()
                      }</td>

                  </tr>

              );

              break;

          case 'client-notes':

              rows = props.data.rows.map((item)=>

                  <tr key={item.id}>
                      <td onClick={()=>{ props.data.onClickNote(item); }}>
                          {item.noteExcerpt}
                      </td>
                      <td>{item.date}</td>
                      <td className="action">
                          <button className="delete-button" onClick={()=>{ props.data.onClickDelete(item.id,props.data); }}>
                              <div></div><div></div>
                          </button>
                      </td>
                  </tr>

              );

              break;

          case 'property-views':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td>{item.views}</td>
                  </tr>

              );

              break;

          case 'recent-views':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/sales-rep/" + item.salesRep.id}>{item.salesRep.name}</Link></td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                  </tr>

              );

              break;

          case 'presentations':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                  </tr>

              );

              break;

          case 'sales-reps':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/sales-rep/" + item.salesRep.id}>{item.salesRep.name}</Link></td>
                  </tr>

              );

              break;

          case 'brokers':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/broker/" + item.broker.id}>{item.broker.name}</Link></td>
                  </tr>

              );

              break;

          case 'general-managers':
              
              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><button onClick={()=> props.onItemClick(item.generalManager)}>{item.generalManager.name}</button></td>
                      <td className="action">
                          <button className="delete-button" onClick={()=>{ props.data.onClickDelete(item.generalManager.id,item.generalManager.name,props.data); }}>
                              <div></div><div></div>
                          </button>
                      </td>
                  </tr>

              );

              break;

          case 'sales-managers':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/sales-manager/" + item.salesManager.id}>{item.salesManager.name}</Link></td>
                  </tr>

              );

              break;

          case 'views-by-client':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td>{item.date}</td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                  </tr>

              );

              break;

          case 'favs-by-client':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                  </tr>

              );

              break;

          case 'recent-sells':

              rows = props.data.rows.map((item, index)=>

                  <tr key={index}>
                      <td><Link to={"/dashboard/property/" + item.property.id}>{item.property.name}</Link></td>
                      <td><Link to={"/dashboard/client/" + item.client.id}>{item.client.name}</Link></td>
                      <td><Link to={"/dashboard/sales-rep/" + item.salesRep.id}>{item.salesRep.name}</Link></td>
                  </tr>

              );

              break;

      }
  }



  return (

      <div className={props.data ? "dashboard-table " + props.data.className : props.height ? "dashboard-table " + props.height : "dashboard-table"}>
          <LoadingOverlay loaded={!!props.data}/>
          {props.data && <div>
              {header}
              <div className="inner">
                  <table className="table table-hover">
                      <thead>
                      <tr>
                          {headings}
                      </tr>
                      </thead>
                      <tbody>
                      {rows}
                      </tbody>
                  </table>
              </div>
          </div>}



        
          
      </div>

  );
  
}

export default DashboardTable;