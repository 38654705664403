import ProjectMarker from "../components/ProjectMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "K-Villas",
    currencyUnit: "£",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: false,
    priceFilter: {
        unit: 10000,
        min: 230000,
        max: 1250000,
    },
    showIntroVideo : true,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "developer@wizio.com.tr"
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'Exterior', link: '/gallery/exterior'},
            {id:2, title:'Interior', link: '/gallery/interior'},
        ],


        location_category : [
            {id : "shopping",title : "Shopping"},
            {id : "social",title : "Social"},
            {id : "health",title : "Health"},
            {id : "public_place",title : "Public"},
            {id : "transportation",title : "Transportation"},
            {id : "schools",title : "Schools"},
        ],
        location : {
            center : [
                {id:0, title:"", lat: 35.33778219375099, lng: 33.52138279068377}
            ],
            shopping : [
                {id:1,title:'Petrol Station',link:"/location/shopping/1", lat:35.34033587282626, lng:33.58131979259244},
            ],

            social :  [
                {id:2,title:'Supermarket and Pharmacy',link:"/location/social/2", lat:35.34172453039216, lng:33.539935321812145},
                {id:3,title:'Tuncay\'in Yeri Restaurant',link:"/location/social/3", lat:35.34173049936661, lng:33.54192132894124,},
                {id:4, title:'Korineum Golf Resort', link: '/location/social/4', lat:35.32563844768587, lng:33.51650678245197, titleDown: true},
                {id:5, title:'Alagadi Beaches', link: '/location/social/5', lat:35.33164107004881, lng:33.482942720249135},
                {id:6,title:'Acapulco Hotel & Resort',link:"/location/social/6", lat:35.329263729034736, lng:33.421935051012355},
                {id:7,title:'Esentepe Village',link:"/location/social/7", lat:35.34033587282626, lng:33.58131979259244},
            ],

            health : [
                {id:8,title:'Girne State Hospital',link:"/location/health/8", lat:35.33772626967682, lng:33.32554894274646},
                {id:9,title:'Suat Gunsel Private Hospital',link:"/location/health/9", lat:35.33544283203706, lng:33.34047528411314},
            ],

            public_place : [
                {id:10,title:'Girne/Kyrenia',link:"/location/public_place/10", lat:35.34154959714187, lng:33.32196506987871 },
                {id:11,title:'Lefkosa/Nicosia',link:"/location/public_place/11", lat:35.1922324,lng:33.3273621},
                {id:12,title:'Famagusta',link:"/location/public_place/12", lat:35.120469258177735, lng:33.93727558158043},
                {id:13,title:'Karpaz',link:"/location/public_place/13", lat:35.65873697503215, lng:34.51961487309561},
            ],
            transportation : [
                {id:14,title:'Ercan International Airport',link:"/location/transportation/14", lat:35.157733827995756, lng:33.502726886094706},
                {id:15,title:'Larnaca International Airport',link:"/location/transportation/15", lat:34.87123742085969, lng:33.60783098596328,},
            ],
            schools : [
                {id:16,title:'English School of Kyrenia',link:"/location/schools/16", lat:35.31641851419015, lng:33.34336568816986},
            ]

        }

    },
    gallery : {

        exterior : [
            {caption:'Exterior 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:'Exterior 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:'Exterior 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:'Exterior 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:'Exterior 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:'Exterior 6', source: require("../img/gallery/exterior/i6.webp")},
            {caption:'Exterior 7', source: require("../img/gallery/exterior/i7.webp")},
            {caption:'Exterior 8', source: require("../img/gallery/exterior/i8.webp")},
            {caption:'Exterior 9', source: require("../img/gallery/exterior/i9.webp")},
            {caption:'Exterior 10', source: require("../img/gallery/exterior/i10.webp")},


        ],
        interior : [
            {caption:'Interior 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'Interior 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'Interior 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'Interior 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'Interior 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'Interior 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'Interior 7', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'Interior 8', source: require("../img/gallery/interior/i8.jpg")},
            {caption:'Interior 9', source: require("../img/gallery/interior/i9.jpg")},
            {caption:'Interior 10', source: require("../img/gallery/interior/i10.jpg")},


        ],



    },
    brochure : [

    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};