import React, {useEffect, useState} from 'react';
import '../../css/Modal.css';
import ButtonWithLoading from "../ButtonWithLoading";
import {appConst} from "../../constant/appConst";
import {appUtil} from "../../constant/appUtil";
import {apiConst} from "../../constant/apiConst";

function ModalAssignSalesRep(props) {
  
  const modalClass = "assign-person";

  const [fields, setFields] = useState({
    user_id : 0
  });
  const [errors, setErrors] = useState({user_id:""});
  const [isLoading,setIsLoading] = useState(false);
  const [salesReps,setSalesReps] = useState(false);

  useEffect(() => {
    let assignableSalesReps = []
    apiConst.getUsersByRole(appConst.role.sales_rep).then(salesReps => {
      salesReps.forEach(salesRep => {
          if(!isInCurrentSalesReps(salesRep))
            assignableSalesReps.push(salesRep);
      })
      setSalesReps(assignableSalesReps);
    })
  },[props.person])



  function isInCurrentSalesReps(salesRep){
    let currentSalesRepIds = [];
    props.person.children.forEach(currentSalesRep => {
      currentSalesRepIds.push(currentSalesRep.id);
    })
    return currentSalesRepIds.includes(salesRep.id);
  }
  function hideModal(){
    appConst.closeModal(modalClass);
  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    if(!fields["user_id"])
    {
      formIsValid = false;
      errors["user_id"] = "Please select a sales rep for assign";
    }



    setErrors(errors)
    return formIsValid;
  }
  function onSave(){

    if(handleValidation())
    {
      setIsLoading(true);

      apiConst.userUpdateParent(fields["user_id"],props.person.id).then(result => {
        setIsLoading(false);

        if(result.response)
        {
          props.onConfirm(result.client);
          hideModal();
        }
      })

    }

  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="modal-body-title">Assign Client</h3>
            
            <div className="form-group">
              <div className="form-element">
                <label htmlFor="lead-source">Clients</label>
                <select className={errors["user_id"] ? "form-control is-invalid" : "form-control"} id="lead-source" onChange={(e) => handleChange("user_id",e)}>
                  <option value="">Select a sales rep</option>
                  { salesReps && salesReps.map( s => (
                      <option key={s.id} value={s.id}>{s.name}</option>
                  ))}
                </select>
                {
                  errors["user_id"] && <div className="invalid-feedback">
                    {errors["user_id"]}
                  </div>
                }
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>Cancel</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label="Save" isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalAssignSalesRep;