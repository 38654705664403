import React, {useState, useEffect, useContext} from 'react';
import {Link,useParams} from "react-router-dom";
import '../../css/dashboard/Client.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import ModalEditNote from './ModalEditNote.js';
import ModalEditClient from './ModalEditClient.js';
import LoadingOverlay from "../LoadingOverlay";
import {apiConst} from "../../constant/apiConst";
import {appUtil} from "../../constant/appUtil";
import ConfirmContext from "../context/ConfirmContext";
import {appConst} from "../../constant/appConst";


function Client(props) {
  const params = useParams();
  const [client,setClient] = useState(null);
  const [activeNote, setActiveNote] = useState(null);
  const [data1,setData1] = useState(null);
  const [data2,setData2] = useState(null);
  const [data3,setData3] = useState(null);
  const [data4,setData4] = useState(null);
  const [data5,setData5] = useState(null);
  const {confirm,setConfirm} = useContext(ConfirmContext);

  let notes = {
    className: "client-notes",
    title: "Notes",
    headings: ["Note", "Date", ""],
    onClickDelete: onClickDeleteNote,
    onClickNote: onClickNoteExcerpt,
    rows: []
  };


  useEffect(() => {
    apiConst.getClient(params.client_id).then(client => {
      setClient(client);

      let data_1 = {
        className: "actions-history",
        title: "Actions history",
        headings: ["Date", "Property", "Status"],
        rows: []
      };

      client.actions.forEach(action => {
        data_1.rows.push({ date: action.date, property: {id: action.property.id, name: action.property.code}, status: action.status.label })
      })
      setData1(data_1);

      let data_2 = {
        className: "favourite-properties",
        title: "Favourite properties",
        headings: ["Property"],
        rows: []
      };

      client.favs.forEach(fav => {
        data_2.rows.push({  property: {id: fav.property.id, name: fav.property.code,status_id: fav.property.status_id}, status: fav.property.status.label,client_id:fav.client.id,current_client_id : client.id })
      })
      setData2(data_2);

      let data_4 = {
        className: "property-views",
        title: "Property views",
        headings: ["Date", "Property", "Views"],
        rows: []
      };

      client.views.forEach(view => {
        data_4.rows.push({ date: view.date,property: {id: view.property.id, name: view.property.code}, views: view.view_count })
      })
      setData4(data_4);

      let data_5 = {
        className: "presentations",
        title: "Presentations",
        headings: ["Date"],
        rows: []
      };

      client.presentations.forEach(presentation => {
        data_5.rows.push({ date: presentation.date})
      })
      setData5(data_5);


      let newNotes = {...notes};
      client.notes.forEach(note => {
        newNotes.rows.push({id:note.id,note : note.note,noteExcerpt: appUtil.trimString(note.note,40),date:note.date})
      })

      setData3(notes);


    })
  },[])

  function saveClient(savedClient){

    setClient(savedClient)
  }

  function clearActiveNote(){
    setActiveNote(null);
  }
  useEffect(() => {

    if(activeNote) appConst.showModal("edit-note")

  },[activeNote])

  function onClickNoteExcerpt(note){
    setActiveNote(note)
  }

  function addNote(){
    setActiveNote({})
  }

  function saveNote(process,savedNote){

    let newNotes = {...notes};
    if(process === "update")
    {
      data3.rows.forEach(note => {
        note = savedNote.id === note.id ? savedNote : note;
        newNotes.rows.push({id:note.id,note : note.note,noteExcerpt: note.note,date:note.date})
      })
    }
    else
    {
      newNotes.rows = [{id:savedNote.id,note : savedNote.note,noteExcerpt: appUtil.trimString(savedNote.note,40),date:savedNote.date},...data3.rows]
    }

    setData3(newNotes);

  }

  function onClickDeleteNote(id,tableData){

    setConfirm({show:true,text:"You want to delete this note",onConfirm : () => onDeleteConfirm(id,tableData)})

  }

  function onDeleteConfirm(id,tableData){

    apiConst.clientNoteDelete(id).then(result => {
      if(result.response)
      {
          let data = {...tableData};
          data.rows = []
          tableData.rows.forEach((note) => {
            if(note.id !== id) data.rows.push(note);
          });
          setData3(data);
      }
    })
  }

  function onFilterAction() {
    apiConst.getClient(params.client_id).then(client => {
      setClient(client);
      let data_2 = {
        className: "favourite-properties",
        title: "Favourite properties",
        headings: ["Property"],
        rows: []
      };

      client.favs.forEach(fav => {
        data_2.rows.push({
          property: {id: fav.property.id, name: fav.property.code, status_id: fav.property.status_id},
          status: fav.property.status.label,
          client_id: fav.client.id,
          current_client_id: client.id
        })
      })
      setData2(data_2);
    });
  }


  return (

    <section className="primary dashboard client">
      
      <DashboardHeader />
      <LoadingOverlay loaded={!!client}/>
      {client && <div className="container">
        <div className="row">

          <div className="col-5">
            <div className="dashboard-box client-profile">

              <div className="header">
                <h3>{client.first_name + " " + client.last_name}</h3>

                <div className="edit-button" onClick={()=>{ appConst.showModal("edit-person") }}>Edit</div>
              </div>

              <div className="row item">
                <div className="col-6">
                  <div>Owner</div>

                </div>
                <div className="col-6">
                  <div>{client.user.name}</div>
                </div>

              </div>
              <div className="row item">
                <div className="col-6">
                  <div>Phone</div>
                  <div>{client.phone}</div>
                </div>

                <div className="col-6 item">
                  <div>Email</div>
                  <div>{client.email}</div>
                </div>
              </div>

              <div className="row item">
                <div className="col-6">
                  <div>Lead status</div>
                  <div>{client.lead_status.label}</div>
                </div>

                <div className="col-6 item">
                  <div>Lead source</div>
                  <div>{client.lead_source.label}</div>
                </div>
              </div>
              
            </div>   

            <DashboardTable data={data1} />

            <DashboardTable data={data2} refreshFunction={onFilterAction} />

          </div>

          <div className="col-7">
            
            {data3 && <DashboardTable data={data3} headerButton={{title: "Add"}} onHeaderButton={addNote} />}
            
            <div className="row">
              <div className="col-7">
                <DashboardTable data={data4} />
              </div>

              <div className="col-5">
                <DashboardTable data={data5} />
              </div>
            </div>

          </div> 
          
        </div>
      </div>}

      {activeNote && <ModalEditNote title={appUtil.isEmpty(activeNote) ? "Add Note" : "Edit Note"} note={activeNote} clearActive={clearActiveNote} onConfirm={saveNote} client={client} confirmText="Save" cancelText="Cancel" />}
      {client && <ModalEditClient title="Edit Client" client={client} onConfirm={saveClient} confirmText="Save" cancelText="Cancel" />}

    </section>

  );
  
}

export default Client;