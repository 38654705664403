import React, {useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import '../../css/dashboard/Property.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {Link} from "react-router-dom";
import {apiConst} from "../../constant/apiConst";
import {useParams} from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import {appUtil} from "../../constant/appUtil";
import {appSettings} from "../../constant/appConst";

function Property(props) {

  const [property,setProperty] = useState(null);
  const [data1,setData1] = useState(null);
  const [data2,setData2] = useState(null);
  const params = useParams();


  useEffect(() => {
    apiConst.getProperty(params.property_id).then(property => {
      setProperty(property);

      let data_1 = {
        className: "views-by-client",
        title: "Views by Client",
        headings: ["Date", "Client"],
        rows: []
      };
      property.views.forEach(view => {
        data_1.rows.push({date:view.date,client : {id:view.client.id ,name: view.client.first_name +" " + view.client.last_name}})
      });
      setData1(data_1);

      let data_2 = {
        className: "favs-by-client",
        title: "Favs by Client",
        headings: ["Client"],
        rows: []
      };
      property.favs.forEach(fav => {
          data_2.rows.push({client : {id:fav.client.id ,name: fav.client.first_name +" " + fav.client.last_name}})
      });
      setData2(data_2);

    })
  },[])

  return (

    <section className="primary dashboard property">
      <DashboardHeader  />
      <LoadingOverlay loaded={!!property}/>
      {property && <div className="container">
        <div className="row">

          <div className="col-4">
            <div className="dashboard-box property-info-box dashboard-property-title">
              <h3>{property.code}</h3>
            </div>
          </div>

          <div className="col-4">
            <div className="dashboard-box property-info-box">
              <div className="content">{property.status.label}</div>
            </div>

            <div className="dashboard-box property-info-box">
              <div className="content">{appUtil.formatPrice(property.price)}</div>
            </div>
          </div>

          <div className="col-4">
            <div className="dashboard-box property-info-box">
              <div className="content">{property.net_area} {appSettings.areaUnit}</div>
            </div>

            <div className="dashboard-box property-info-box">
              <div className="content">{property.room_type.label}</div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-2">
            <NumberBox className="number-box" number={property.views.length} text="Total views" />
            <NumberBox className="number-box" number={property.favs.length} text="Total favs" />
          </div>

          <div className="col-5">
            <DashboardTable data={data1} />
          </div>

          <div className="col-5">
            <DashboardTable data={data2} />
          </div>

        </div>

      </div>}


      
    </section>

  );
  
}

export default Property;