import React, {useContext} from 'react';
import "../css/Favorites.css";
import FavCompareContext from "./context/FavCompareContext";
import GridItem from "./GridItem";
import FavCompareCard from "./FavCompareCard";
function Favorites(props) {
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);


    return (
        <section className={"primary narrow favorites-section"}>
            <div className="favorites-view">
                <div className={favCompareList.length === 0 ? "favorites-view-container" : "favorites-view-container has-item"}>
                    { favCompareList.length === 0 &&
                        <div className="empty-text">
                            <span className={"compare-icon"}></span>
                            <h3>Add Comparison</h3>
                            <p>You can compare the apartments you have added to your favorites from this area.</p>
                        </div>
                    }
                    { favCompareList.length > 0 &&
                        <div className={"compare-list"}>
                            {favCompareList.map(item =>
                                    <FavCompareCard key={item.id} fav={item} />)}
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default Favorites;