import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Brokers.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import UserContext from "../context/UserContext";

import ModalEditSalesPerson from "./ModalEditSalesPerson";



function Brokers() {
    const {user,setUser} = useContext(UserContext);
    const [data1,setData1] = useState(null);
    const [brokers,setBrokers] = useState(null);
    const [topBrokers,setTopBrokers] = useState({
        broker1 : {total:-1,name:"No One"},
        broker2 : {total:-1,name:"No One"},
        broker3 : {total:-1,name:"No One"},
    });
    const [activePerson,setActivePerson] = useState(null);
    useEffect(() => {
        apiConst.getBrokers().then(brokers => {
                setBrokers(brokers);
        })
    },[])

    useEffect(() => {
        if(brokers)
        {
            let data_1 = {
                className: "brokers",
                title: "Brokers",
                headings: [],
                rows: []
            };
            brokers.forEach(broker => {
                data_1.rows.push({
                    broker: {id: broker.id, name: broker.name}
                })
            })
            setData1(data_1);

            brokers.sort((a, b) => (a.totals.sold < b.totals.sold) ? 1 : -1);

            let data_2 = {...topBrokers}

            if(brokers.length >= 1)
            {
                data_2.broker1.name = brokers[0].name
                data_2.broker1.total = brokers[0].totals.sold
            }
            if(brokers.length >= 2)
            {
                data_2.broker2.name = brokers[1].name
                data_2.broker2.total = brokers[1].totals.sold
            }
            if(brokers.length >= 3)
            {
                data_2.broker3.name = brokers[2].name
                data_2.broker3.total = brokers[2].totals.sold
            }

            setTopBrokers(data_2);
        }
    },[brokers])

    useEffect(() => {
        if(activePerson) appConst.showModal("edit-person");
    },[activePerson])

    function addPerson(){
        setActivePerson({})
    }

    function savePerson(process,savedPerson){

        let data = {
            className: "brokers",
            title: "Brokers",
            headings: [],
            rows: []
        };
        if(process === "create")
        {
            data.rows = [{broker : {id: savedPerson.id, name: savedPerson.name}},...data1.rows];
            setData1(data);

        }

    }
  

  return (

    <section className="primary dashboard sales-reps">
      <DashboardHeader slug="brokers" />
  
        <div className="container">
        <div className="row">
          
        <div className="col-4">
          <DashboardTable data={data1} headerButton={{title: "Add"}} onHeaderButton={addPerson} height="h-80" />
         </div>

         <div className="col-8">
            <div className="dashboard-box best-sales-reps">
              <h3 className="text-center">Best Brokers</h3>

              <div className="row">
                
                <NumberBox className="number-box" number={topBrokers.broker1.total} text={topBrokers.broker1.name} />
                <NumberBox className="number-box" number={topBrokers.broker2.total} text={topBrokers.broker2.name} />
                <NumberBox className="number-box" number={topBrokers.broker3.total} text={topBrokers.broker3.name} />

              </div>

            </div>

           
            

         </div>
          
        </div>

        

      </div>
        {activePerson && <ModalEditSalesPerson title="Add Broker" person={activePerson} onConfirm={savePerson} confirmText="Save" cancelText="Cancel" role={appConst.role.broker} />}

    </section>

  );
  
}

export default Brokers;