import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/SalesManagers.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import UserContext from "../context/UserContext";
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import ModalEditSalesPerson from "./ModalEditSalesPerson";



function SalesManagers() {

    const {user,setUser} = useContext(UserContext);
    const [data1,setData1] = useState(null);
    const [salesManagers,setSalesManagers] = useState(null);
    const [topSalesManagers,setTopSalesManagers] = useState({
        salesManager1 : {total:-1,name:"No One"},
        salesManager2 : {total:-1,name:"No One"},
        salesManager3 : {total:-1,name:"No One"},
    });
    const [activePerson,setActivePerson] = useState(null);
    useEffect(() => {
        apiConst.getUser(user.id).then(user => {

            setSalesManagers(user.director.sales_managers);
        })
    },[])

    useEffect(() => {
        if(salesManagers)
        {
            let data_1 = {
                className: "sales-managers",
                title: "Sales Managers",
                headings: [],
                rows: []
            };
            salesManagers.forEach(salesManager => {
                data_1.rows.push({
                    salesManager: {id: salesManager.id, name: salesManager.name}
                })
            })
            setData1(data_1);

            salesManagers.sort((a, b) => (a.totals.sold < b.totals.sold) ? 1 : -1);

            let data_2 = {...topSalesManagers}

            if(salesManagers.length >= 1)
            {
                data_2.salesManager1.name = salesManagers[0].name
                data_2.salesManager1.total = salesManagers[0].totals.sold
            }
            if(salesManagers.length >= 2)
            {
                data_2.salesManager2.name = salesManagers[1].name
                data_2.salesManager2.total = salesManagers[1].totals.sold
            }
            if(salesManagers.length >= 3)
            {
                data_2.salesManager3.name = salesManagers[2].name
                data_2.salesManager3.total = salesManagers[2].totals.sold
            }

            setTopSalesManagers(data_2);
        }
    },[salesManagers])


    useEffect(() => {
        if(activePerson) appConst.showModal("edit-person");
    },[activePerson])

    function addPerson(){
        setActivePerson({})
    }

    function savePerson(process,savedPerson){

        let data = {
            className: "sales-managers",
            title: "Sales Managers",
            headings: [],
            rows: []
        };
        if(process === "create")
        {
            data.rows = [{salesManager : {id: savedPerson.id, name: savedPerson.name}},...data1.rows];
            setData1(data);

        }

    }

  

  return (

    <section className="primary dashboard sales-managers">
      <DashboardHeader slug="sales-managers" showMenu="true" />
  
        <div className="container">
        <div className="row">
          
        <div className="col-4">
          <DashboardTable data={data1} headerButton={{title: "Add"}}  onHeaderButton={addPerson} height="h-80" />
         </div>

         <div className="col-8">
            <div className="dashboard-box best-sales-managers">
              <h3 className="text-center">Best Sales Managers</h3>

              <div className="row">

                  <NumberBox className="number-box" number={topSalesManagers.salesManager1.total} text={topSalesManagers.salesManager1.name} />
                  <NumberBox className="number-box" number={topSalesManagers.salesManager2.total} text={topSalesManagers.salesManager2.name} />
                  <NumberBox className="number-box" number={topSalesManagers.salesManager3.total} text={topSalesManagers.salesManager3.name} />

              </div>

            </div>

           
            

         </div>
          
        </div>

        

      </div>
        {activePerson && <ModalEditSalesPerson title="Add Sales Manager" person={activePerson} onConfirm={savePerson} confirmText="Save" cancelText="Cancel" role={appConst.role.sales_manager} />}
    </section>

  );
  
}

export default SalesManagers;