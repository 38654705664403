import React, {useContext} from 'react';
import {appConst, appSettings} from "../constant/appConst";
import {Redirect, Route} from "react-router-dom";
import UserContext from "./context/UserContext";

const UserPrivateRoute = ({ component: Component, ...rest }) => {
    const {user,setUser} = useContext(UserContext);
    return <Route {...rest} render={(props) => (
        user ? (user.email !== appSettings.botSalesRep ? <Component {...props} /> : <Redirect to='/' />) : <Redirect to='/' />
    )} />
}



export default UserPrivateRoute;