import React, {useContext, useEffect} from 'react';
import "../css/SplashScreen.css";
import Preloader from "./Preloader";
import AppDataContext from "./context/AppDataContext";
import video from '../img/video/wizio.mp4'
import IntroVideo from "./IntroVideo";
const SplashScreen = ({loaded360}) => {
    const { appData, setAppData} = useContext(AppDataContext);

    useEffect(() => {

        if(loaded360 && appData.loaded)
        {
            document.getElementById("splash-screen").classList.add("hide-splash")
        }

    },[loaded360,appData.loaded])

    return (
        <div id="splash-screen" className="splash-screen">

            <div className="splash-screen-container">
                <img src={require("../img/logo-splash.svg")} width={350} />
                <div className="mt-4">
                    <Preloader color={"dark"}/>
                </div>

            </div>

        </div>
    );
};

export default SplashScreen;