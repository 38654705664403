import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";

const SubMenuItem = ({title,link,isLast}) => {

    const location = useLocation();
    const [isActive,setIsActive] = useState(false);

    useEffect(()=> {

        setIsActive(location.pathname === link)
    },[location])

    return (
        <div>
            <Link to={link} className={isActive ? "submenu-item submenu-item--active" : "submenu-item"}>{title}</Link>
        </div>


    );
};

export default SubMenuItem;