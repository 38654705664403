import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import 'swiper/swiper-bundle.css';
import "../css/Slider.css"
const Slider = ({gallery}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeSlide,setActiveSlide] = useState(0)
    const [caption,setCaption] = useState(gallery[0].caption)
    const [dark,setDark] = useState(false)
    const totalSlide = gallery.length;




    function onSliderChange(swiper)
    {
        console.log(swiper);
        setActiveSlide(swiper.realIndex);
        setCaption(gallery[swiper.realIndex].caption);
        setDark(gallery[swiper.realIndex].darktext);
    }

    return (
        <div className="slider" onContextMenu={(e)=> e.preventDefault()}>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={onSliderChange}
                thumbs={{ swiper: thumbsSwiper }}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="main-swiper"
            >
                {gallery.map ((item,i) => {
                    return (<SwiperSlide key={i}><img src={item.source} /></SwiperSlide>)
                })}

            </Swiper>

            <Swiper
                onSwiper={setThumbsSwiper}
                loop={false}
                spaceBetween={10}
                slidesPerView={14}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="thumb-swiper"
            >
                {gallery.map ((item,i) => {
                    return (<SwiperSlide key={i}><img src={item.source} /></SwiperSlide>)
                })}
            </Swiper>

            <p className={dark ? "caption dark" : "caption"}>{caption}</p>
            <p className="active-slide">{activeSlide+1}/{totalSlide}</p>
        </div>
    );
};

export default Slider;