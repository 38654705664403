import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import '../../css/dashboard/Properties.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import {appUtil} from "../../constant/appUtil";
import ModalEditProperty from './ModalEditProperty.js';
import AppDataContext from "../context/AppDataContext";

function Properties() {
  const {appData, setAppData} = useContext(AppDataContext);
  const [data1,setData1]= useState(null);
  const [totals,setTotals] = useState(null);
  const [activeProperty, setActiveProperty] = useState(null);

  useEffect(() => {
    apiConst.getProperties().then(properties => {

      let data = {
        className: "properties",
        title: "Properties",
        headings: ["Code", "Floor Type", "Net Area",  "Status"],
        onClickPropertyColumn: onClickPropertyColumn,
        rows: []
      };

      let totalsValue = {
        available : 0, option:0, sold:0, blocked:0
      }

      properties.forEach(property => {
        data.rows.push({
          id: property.id,
          name: property.code,
          floorType: property.room_type.label,
          netArea: property.net_area,

          status: property.status
        });
        if(property.status.id === appConst.status.available) totalsValue.available++
        if(property.status.id === appConst.status.reserved) totalsValue.option++
        if(property.status.id === appConst.status.sold) totalsValue.sold++
        if(property.status.id === appConst.status.not_released) totalsValue.blocked++
      })

      setData1(data);
      setTotals(totalsValue);

    })
  },[])

  useEffect(() => {
    if(activeProperty) {
      appConst.showModal("edit-property")
    }

  },[activeProperty]);

  function onClickPropertyColumn(property){
    setActiveProperty(property);
  }

  function clearActiveProperty(){
    setActiveProperty(null);
  }

  function saveProperty(savedProperty){
    console.log(savedProperty);

    let data = {
      className: "properties",
      title: "Properties",
      headings: ["Code", "Floor Type", "Net Area",  "Status"],
      onClickPropertyColumn: onClickPropertyColumn,
      rows: []
    };


    data1.rows.forEach(property => {

      property = savedProperty.id === property.id ? savedProperty : property;
      data.rows.push({
        id: property.id,
        name: property.name,
        floorType: property.floorType,
        netArea: property.netArea,
        status: property.status
      });
    })

    setData1(data);

    let newAppData = {...appData}
    newAppData.properties.forEach(function(currentProperty,i){
      if(savedProperty.id === currentProperty.id)
      {
        currentProperty.status_id = savedProperty.status.id;
        currentProperty.status.id = savedProperty.status.id;
        if(savedProperty.status === appConst.status.available)
          currentProperty.status.label = "Available";
        if(savedProperty.status === appConst.status.sold)
          currentProperty.status.label = "Sold";
        if(savedProperty.status === appConst.status.reserved)
          currentProperty.status.label = "Reserved";
        if(savedProperty.status === appConst.status.not_released)
          currentProperty.status.label = "Not Released";
      }
    });
    newAppData.filteredProperties.forEach(function(currentProperty,i){
      if(savedProperty.id === currentProperty.id)
      {
        currentProperty.status_id = savedProperty.status.id;
        currentProperty.status.id = savedProperty.status.id;
        if(savedProperty.status === appConst.status.available)
          currentProperty.status.label = "Available";
        if(savedProperty.status === appConst.status.sold)
          currentProperty.status.label = "Sold";
        if(savedProperty.status === appConst.status.reserved)
          currentProperty.status.label = "Reserved";
        if(savedProperty.status === appConst.status.not_released)
          currentProperty.status.label = "Not Released";
      }
    });

    newAppData.floorStatuses = newAppData.getFreshFloorStatuses();
    newAppData.buildingStatuses = newAppData.getFreshBuildingStatuses();
    window.updateBuildingStatuses(newAppData.buildingStatuses);
    setAppData(newAppData);

    
  }




  return (

    <section className="primary dashboard properties">
      <DashboardHeader slug="properties" />
  
        <div className="container">
        <div className="row">
          
          <div className="col-2">

            <NumberBox className="number-box" number={totals ? totals.available : "-1"} text="Available" />
            <NumberBox className="number-box" number={totals ? totals.sold : "-1"} text="Sold" />
            <NumberBox className="number-box" number={totals ? totals.option : "-1"} text="Reserved" />
            <NumberBox className="number-box" number={totals ? totals.blocked : "-1"} text="Not Released" />
            
          </div>

          

          <div className="col-10">

            <div className="row">
              <div className="col-12">
                <DashboardTable data={data1} height="h-80" />
              </div>
            </div>

         </div>
          
        </div>
      </div>

    {activeProperty && <ModalEditProperty title="Edit Property" property={activeProperty} clearActive={clearActiveProperty} onConfirm={saveProperty} confirmText="Save" cancelText="Cancel" />}
    

    </section>

  );
  
}

export default Properties;