import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const PropertyPlan = (props) => {


    if(props.svgValue == null) return null;

    return (
        <Link to={props.link}>
            <g className={props.className}>
                <path className="cls-2" d={props.svgValue.path}/>
                <text className="title" x={props.svgValue.x} y={props.svgValue.y} >{props.title}</text>
                <text className="subtitle" x={props.svgValue.x} y={props.svgValue.y} >{props.roomType}</text>
                <circle className="cls-3" cx={props.svgValue.x} cy={props.svgValue.y} r="16"/>
            </g>
        </Link>
    );
};

export default PropertyPlan;