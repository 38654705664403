import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";
import {propertyConst} from "../constant/propertyConst";

import "../css/FavoriteListItem.css";
import FavCompareContext from "./context/FavCompareContext";
import {apiConst} from "../constant/apiConst";
import FavContext from "./context/FavContext";

function FavoriteListItem({fav}) {
    const {favs,setFavs} = useContext(FavContext);
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);
    const [isInCompareList,setIsInCompareList] = useState(appUtil.containsObject(fav,favCompareList));


    function onCompareChange(){
        let newFavCompareList = [...favCompareList];
        if(!appUtil.containsObject(fav,favCompareList)) {
            newFavCompareList.push(fav);

        }
        else {

            favCompareList.forEach((item,i) => {
                if(item === fav) {
                    newFavCompareList.splice(i,1)
                }
            })

        }
        setFavCompareList(newFavCompareList);

    }


    useEffect(() => {
        setIsInCompareList(appUtil.containsObject(fav,favCompareList))

    },[favCompareList])

    function getImage(){
        let thumbs = propertyConst.thumb[fav.property.room_type_folder];
        return (thumbs[0].source);
    }

    function onClickDelete(){
        apiConst.favDelete(fav.id).then(result => {
            if(result.response)
            {

                let newFavs = [...favs];
                if(newFavs.length)
                {
                    newFavs.forEach((item,i) => {
                        if(item.id === fav.id) newFavs.splice(i,1);
                    });
                    setFavs(newFavs);
                    sessionStorage.setItem("favs",JSON.stringify(newFavs));

                    let newFavCompareList = [...favCompareList];
                    favCompareList.forEach((item,i) => {
                        if(item === fav) {
                            newFavCompareList.splice(i,1)
                        }
                    })
                    setFavCompareList(newFavCompareList);

                }
            }
        })
    }
    return (
        <div className={isInCompareList ? "fav-item in-compare-list" : "fav-item"}>

                <Link className={"fav-item-img"} to={"/property-view/" + fav.property.id} style={{
                    backgroundImage: "url(" + getImage() + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>

                </Link>

            <div className={"fav-item-text"}>
                <h3>{fav.property.code}</h3>
                <span className={"room-type-text"}>{fav.property.room_type.label}</span>
                {appSettings.showPrices && <span className={"price-text"}>{appUtil.formatPrice(fav.property.price)}</span>}

            </div>
            <div className={"fav-item-buttons"}>
                <button className={"btn-delete-fav"} onClick={onClickDelete}><span className="icon"></span></button>
                <button className={"btn-compare-fav"} onClick={onCompareChange}><span className="icon"></span></button>
            </div>
        </div>
    );
}

export default FavoriteListItem;