import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {appConst} from "../constant/appConst";

const SearchMenuItem = ({title,link,status,isLast}) => {

    const location = useLocation();
    const [isActive,setIsActive] = useState(false);
    const [statusClass,setStatusClass] = useState();

    useEffect(()=> {
        if(status === appConst.status.available) setStatusClass("available")
        if(status === appConst.status.sold) setStatusClass("sold")
        if(status === appConst.status.reserved) setStatusClass("reserved")
        if(status === appConst.status.not_released) setStatusClass("not_released")
    },[])

    useEffect(()=> {

        setIsActive(location.pathname === link)
    },[location])

    return (
        <div>
            <Link to={link} className={isActive ? "submenu-item submenu-item--active search-menu-item" : "submenu-item search-menu-item"}>{title} {statusClass && <span className={"property-status-icon " + statusClass}/>}</Link>
        </div>


    );
};

export default SearchMenuItem;