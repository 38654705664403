import React, {useContext} from 'react';
import {useEffect} from 'react';
import MainView from "./MainView";
import ConfirmContext from "./context/ConfirmContext";


function Home({loaded360}) {

  useEffect(()=> {
    //
    //console.log("360 : ",loaded360)
  },[])
  return <MainView loaded360={loaded360}/>;
  
}

export default Home;