import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import '../../css/dashboard/Clients.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import UserContext from "../context/UserContext";
import {apiConst} from "../../constant/apiConst";

function Clients(props) {
  
  const {user,setUser} = useContext(UserContext);
  const [chartType, setChartType] = useState(0);
  const [data1,setData1] = useState(null);
  const [topClients,setTopClients] = useState({
    client1 : {total:-1,name:"No One"},
    client2 : {total:-1,name:"No One"},
    client3 : {total:-1,name:"No One"},
  });
  const [dataMonthly,setDataMonthly] = useState(null);
  const [dataYearly,setDataYearly] = useState(null);



  useEffect(() => {
    let data = {
      className: "client-lead-status",
      title: "Clients",
      headings: ["Client", "Lead Status"],
      rows: []
    };

    apiConst.getUserClients(user.id).then(clients => {
      clients.forEach(client => {
        data.rows.push({ client: {id: client.id, name: client.first_name + " " + client.last_name}, leadStatus: client.lead_status.label })
      });
      setData1(data);
    })

    apiConst.getUserPopularClients(user.id).then(clients => {
      let data = {...topClients}
      if(clients.length >= 1)
      {
        data.client1.name = clients[0].first_name +" "+ clients[0].last_name
        data.client1.total = clients[0].presentations.length
      }
      if(clients.length >= 2)
      {
        data.client2.name = clients[1].first_name +" "+ clients[1].last_name
        data.client2.total = clients[1].presentations.length
      }
      if(clients.length >= 3)
      {
        data.client3.name = clients[2].first_name +" "+ clients[2].last_name
        data.client3.total = clients[2].presentations.length
      }

      setTopClients(data);
    });

    apiConst.getNewClientsByMonthAndYear(user.id).then(data => {
      let chartMonthlyData = [];
        data.monthly.forEach(month => {
          chartMonthlyData.push({name:month[0],pv:month[1]})
        })
      setDataMonthly(chartMonthlyData);

      let chartYearlyData = [];
      data.yearly.forEach(year => {
        chartYearlyData.push({name:year[0],pv:year[1]})
      })
      setDataYearly(chartYearlyData);
    });



  },[])

  return (

  

    <section className="primary dashboard clients">
      
      <DashboardHeader slug="clients" />

      <div className="container">
        <div className="row">
          
         <div className="col-6">
            <DashboardTable data={data1} />
         </div>

         <div className="col-6">
            <div className="most-presented-clients">

              <h3 className="mb-0">MOST PRESENTED CLIENTS</h3>

              <div className="row mt-0">
                
                <NumberBox className="col-4 number-box" number={topClients.client1.total} text={topClients.client1.name} />
                <NumberBox className="col-4 number-box" number={topClients.client2.total} text={topClients.client2.name} />
                <NumberBox className="col-4 number-box" number={topClients.client3.total} text={topClients.client3.name} />

              </div>

            </div>

         </div>
          
          
        </div>

        <div className="row">
          <div className="col-12">

            

            <div className="chart-container new-clients">

            <div className="header">
              <h3>New Clients</h3>
              
              <select className="form-control" onChange={(e)=>{
                
                setChartType(e.target.value);
                
              }}>
                <option value="0">Monthly</option>
                <option value="1">Yearly</option>
              </select>

            </div>
            
            {chartType == 0 && (
              <ResponsiveContainer width="100%" height="80%">
                <LineChart data={dataMonthly}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  
                  <Line dataKey="pv" stroke={getComputedStyle(document.documentElement).getPropertyValue('--chart-first-color').trim()} strokeWidth="2" dot={{ r: 6 }} />
                </LineChart>
              </ResponsiveContainer>
            )}

            

            {chartType == 1 && (
              <ResponsiveContainer width="100%" height="80%">
                <LineChart data={dataYearly}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  
                  <Line dataKey="pv" stroke={getComputedStyle(document.documentElement).getPropertyValue('--chart-second-color').trim()} strokeWidth="2" dot={{ r: 6 }} />
                </LineChart>
              </ResponsiveContainer>
            )}

            
              

            </div>
          </div>
        </div>

      </div>




    </section>

  );
  
}

export default Clients;